import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import axios from 'axios';
import logoDirecional from './Logo_Direcional.png'; // Importa a logo

function App() {
    const [message, setMessage] = useState('');
    const [conversation, setConversation] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const chatBoxRef = useRef(null);

    // Determina a URL da API dinamicamente
    const API_URL = process.env.NODE_ENV === 'production'
        ? 'https://sua-api-prod.com/api/ask' // URL para produção
        : 'http://35.173.190.133:3001/api/ask'; // URL para desenvolvimento

    const handleSendMessage = async (inputMessage) => {
        const content = inputMessage || message;
        if (!content || isLoading) return;

        const userMessage = { role: 'user', content };
        setConversation((prev) => [...prev, userMessage]);

        setMessage('');
        setIsLoading(true); // Inicia o estado de loading

        try {
            const response = await axios.post(API_URL, { message: content });
            const assistantMessage = { role: 'assistant', content: ` ${response.data.reply}` };
            setConversation((prev) => [...prev, assistantMessage]);
        } catch (error) {
            console.error('Erro ao comunicar com a API:', error.response?.data || error.message);
            alert('Erro ao comunicar com a API do backend. Verifique o console para detalhes.');
        } finally {
            setIsLoading(false); // Finaliza o estado de loading
        }
    };

    const handleClearChat = () => {
        setConversation([]); // Limpa o histórico localmente
        setMessage(''); // Limpa a caixa de texto
        axios.post(`${API_URL.replace('/ask', '/reset')}`) // Endpoint para limpar histórico no backend
            .catch((err) => console.error('Erro ao limpar histórico no backend:', err));
    };

    const predefinedQuestions = [
        "Você já se imaginou morando em um lugar que está se transformando em um bairro planejado?",
        "Sabia que morar em um bairro planejado pode reduzir seus gastos mensais?",
        "O que acha de um lugar onde seus filhos podem brincar ao ar livre com segurança?",
        "Como seria viver em um lugar com infraestrutura completa e segurança?",
        "Você sabia que pode usar o Minha Casa Minha Vida para realizar o sonho da casa própria?",
        "Por que não investir no imóvel ideal para sua família e sair do aluguel de vez?",
        "Que tal conhecer mais sobre as vantagens de morar em um bairro planejado?",
        "Já pensou em quanto conforto e segurança um empreendimento Direcional pode oferecer?"
    ];

    // Ajusta o scroll para sempre exibir a última mensagem
    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [conversation]);

    return (
        <div className="App">
            <div className="chat-box" ref={chatBoxRef}>
                {/* Logo como marca d'água */}
                <img
                    src={logoDirecional}
                    alt="Logo Direcional"
                    className="watermark-logo"
                />
                {conversation.map((msg, index) => (
                    <div key={index} className={`message ${msg.role}`}>
                        <span className={`prefix ${msg.role}`}>
                            {msg.role === 'user' ? 'Eu: ' : 'IA do corretor:'}
                        </span>
                        <div className="message-content">{msg.content}</div>
                    </div>
                ))}
            </div>

            <div className="input-box">
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Digite sua mensagem..."
                    disabled={isLoading} // Desativa enquanto envia
                />
                <button onClick={() => handleSendMessage()} disabled={isLoading}>
                    {isLoading ? 'Enviando...' : 'Enviar'}
                </button>
                <button className="clear-button" onClick={handleClearChat}>Limpar Chat</button>
            </div>

            <div className="predefined-questions">
                {predefinedQuestions.map((question, index) => (
                    <button
                        key={index}
                        className="question-box"
                        onClick={() => handleSendMessage(question)}
                        disabled={isLoading} // Desativa enquanto envia
                    >
                        {question}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default App;

